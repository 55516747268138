@import 'mixins';

$defaultFont: 'Open Sans', sans-serif;
$menuFont: 'Yanone Kaffeesatz', sans-serif;
$menuColor: #434244;

/*primary*/
$buttonTextColor: #fff;
$buttonColor: #3399cc;
$buttonBorderColor: #3399cc;

/*default*/
$buttonDefaultTextColor: #333;
$buttonDefaultColor: #fff;
$buttonDefaultBorderColor: #ccc;

$searchColor: #c1c2c0;
$searchMobileColor: #434244;

/*checkout*/
$backgroundCheckout: #f5f5f5;

/*trash*/
$buttonTrashColor: #39c;

.HeaderLogo {
    background-image: url(Images/logo.png);
    width: 200px;

    @include fromSizeSM() {
        width: 251px;
        height: 57px;
    }
}

.btn-primary {
    color: $buttonTextColor;
    background-color: $buttonColor;
    border-color: $buttonBorderColor;

        &:hover {
            background-color: lighten($buttonColor, 5%);
            border-color: $buttonBorderColor;
    }
}

.btn-default {
    color: $buttonDefaultTextColor;
    background-color: $buttonDefaultColor;
    border-color: $buttonDefaultBorderColor;
}

.btn:focus, 
.btn:active:focus, 
.btn.active:focus, 
.btn.focus, 
.btn:active.focus, 
.btn.active.focus {
    outline: 0px;
}

.menu-container {
    padding-top: 43px;
    background: $buttonColor;

    @include fromSizeSM() {
        padding-top: 0;
        background: $menuColor;
    }
}

.SubMenuBox,
.navbar-default .navbar-nav > li > a {
    &:hover,
    &:focus,
    &:active {
        background: lighten($menuColor, 10%);
    }
}

 .navbar-nav>li:first-child > a {
    @include fromSizeMD(){
    padding-left: 15px;
    }
}

.navbar-nav>li:last-child > a {
    @include fromSizeMD(){
    padding-right: 15px;
    }
}

.InfoMenu {
    color: $menuColor;
}

.LogInButton {
    color: $menuColor;
}

.dropdown-menu a,
.dropdown-menu .title,
.dropdown-menu .menu-show-all {
    color: $menuColor;
}

.mcm .dropdown.mcm-fw .dropdown-menu {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,2/10);
    border-top: none;
}

.footer-container {
    background: $menuColor;
    color: white;
}

.copyright-container {
    background: $buttonColor;
    color: white;
    padding: 10px 0;
}

.copyright-container a {
    color: white;
}

a.cart-small-goto-checkout.btn.btn-primary {
    background: $buttonColor;
    border: none;
}

.free-shipping-counter {
    background: $buttonColor;
}

.ProductMenu a {
    color: $menuColor;
}

.ProductMenu .Level1Selected {
    background: $menuColor;
}

.ProductMenu .Level1Selected a {
    color: white;
}

.ProductMenu .Level2Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 30px center;

    @include toSizeMD {
        background-position-x: 6px;
    }
}

.ProductMenu .Level3Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 45px center;

    @include toSizeMD {
        background-position-x: 16px;
    }
}

.section-menu-row {
    background: $menuColor;
    border-top: 1px solid $buttonColor;
    border-bottom: 1px solid $buttonColor;
}

.section-menu-item:hover {
    border-top: 2px solid $buttonColor;
}

.ProductInfo .section-header span::after {
    background: $buttonColor;
}

.BreadCrumb a:last-child {
    color: $buttonColor;
    font-weight: bold;
}

.Layout3Element .favorite-button,
.Layout3Element .ad-buy-button {
    background: transparent;

    @include fromSizeSM() {
        background: $buttonColor;
        color: white;
    }
}

.Layout3Element .fav-remove-button {
    background: transparent;

    @include fromSizeSM() {
        background: $buttonTrashColor;
        color: #fff;
        border-left: 1px solid;
    }
}

.Layout3Element .ad-buy-button {
    border-right-color: white;
}

.buy-product,
.scoll-config-summary {
    font-weight: 300;
    font-family: $menuFont;
    background: $buttonColor;
    color: white;
    border-radius: 20px;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonColor, 10%);
        outline: none;
    }
}

.btn-favorite {
    font-weight: 300;
    font-family: $menuFont;
    background: $buttonDefaultColor;
    color: $buttonDefaultTextColor;
    border-radius: 20px;  
    
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonDefaultColor, 10%);
        outline: none;
    }        
}

.remove-favorites-text {
    display:none;
}


.TableList .BuyButtonSmall,
.TableList .VariantShowMore {
    border-radius: 0;
    background: $buttonColor;
    font-family: $menuFont;
    font-size: 18px;

    &:hover {
        background: darken($buttonColor, 10%);
    }
}

.TableList .BuyButtonSmallDisabled {
    border-radius: 0;
    font-family: $menuFont;
    font-size: 18px;
}

.PrdListCellsPagingTop a,
.PrdListCellsPagingBottom a,
.FieldPaging a {
    &:hover {
        background: $buttonColor;
    }
}

.PrdListCellsPagingTop a.SelectedPagingIndex,
.PrdListCellsPagingBottom a.SelectedPagingIndex,
.FieldPaging a.SelectedPagingIndex {
    background: $buttonColor;
}

.less-qty,
.more-qty {
    border-radius: 50%;
    border-color: $menuColor;
    background: transparent;
    color: $menuColor;
    margin-top: 6px;

    &:hover {
        background: #eee;
    }
}

.less-qty {
    margin-right: 5px;
}

.more-qty {
    margin-left: 5px;
}

.QuantityTextBox {
    background: transparent;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    border: 1px solid $menuColor;
    color: $menuColor;
}

/* the checked style using the :checked pseudo class */
.radio-inline input[type="radio"]:checked + span:before {
    background-color: $buttonColor;
}

.list-type-button {
    color: $menuColor;
}

.search-btn {
    /*color: $searchMobileColor;*/
    @include fromSizeSM {
        color: $searchColor;
        font-size: 28px;
    }
}

.search-btn-text {
    display: none;
}

.checkout,
.checkout-left,
.checkout-right {
    background-color: $backgroundCheckout;
}

.body-checkout .PageContainerBorder {
    @include toSizeLG {
        background-color: $backgroundCheckout;
    }
}
